@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins";
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  font-size: 100%;
  overflow-x: hidden;
}

.titleSection {
  background-image: url("./images/background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.aboutSection {
  background-image: url("./images/background2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.contact {
  background-image: url("./images/background3.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.myHover:hover {
  background-color: underline;
}

li {
  border-left: solid 2px white;
  margin-top: 15px;
  padding: 3px 10px;
}

@layer components {
  .navItem {
    @apply hover:underline px-[25px] text-[18px];
  }
}
